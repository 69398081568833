<template>
    <div>
        <b-overlay :show="loading" no-wrap></b-overlay>
        <b-row>
            <b-col :cols="12" style="overflow-x: auto;">
                <zw-table ref="table"
                          tableName="customer-documents"
                          base-table="customer-documents"
                          columnsPrefix="customer-document.column."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          title="common.title.customer-documents"
                          :actions-list="getActions()"
                          :sticky="false"
                          sticky-header="100%"
                >

                    <template #cell(id)="row">
                        <a href="#" @click.prevent="$root.$children[0].openModal('customer-document-modal', {id:row.item.id,customerId: customerId}, refreshTable)">{{ row.item.id }}</a>
                    </template>
                    <template #cell(name)="row">
                        <a href="#" @click.prevent="customerDocumentUrl(row.item.name)">{{ row.item.name }}</a>
                    </template>
                    <template #cell(mails)="row">
                        <div v-for="mail in row.item.mails"
                             :key="mail.id"
                        >
                            {{ mail.created_at | formatDate('DD.MM.YYYY HH:mm:ss') }}
                            <span v-if="mail.is_send">
                            ({{ $t('common.label.send') }})
                            </span>
                            <span v-else>
                            ({{ $t('common.label.not_send') }})
                            </span>
                        </div>
                    </template>
                    <template #cell(actions)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="$root.$children[0].openModal('customer-document-modal', {id:row.item.id,customerId: customerId}, refreshTable)">
                                {{ $t('common.button.edit') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="sendMail(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="envelope"/>
                                {{ $t('common.button.send_mail') }}
                            </b-dropdown-item>
                            <b-dropdown-item @click="deleteDocument(row.item.id)">
                                <font-awesome-icon class="mr-2" icon="trash"/>
                                {{ $t('common.button.delete') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="$root.$children[0].openModal('todo-modal', {document_id: row.item.id, customer_id: row.item.customer_id}, refreshTable)">
                                {{ $t('common.button.add_todo') }}
                            </b-dropdown-item>
                            <b-dropdown-item
                                @click="downloadCustomerDocumentUrl(row.item.name)">
                                {{ $t('common.button.download') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'

export default {
    name: 'CustomerDocuments',
    mixins: [commonTable],
    props: {
        customerId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            loading: true,
            saveFilters: false,
        }
    },
    mounted() {
        this.shown()
    },
    methods: {
        ...mapGetters('Customer', ['getCustomerDocumentsTable']),
        shown() {
            this.loading = false
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Customer/fetchCustomerDocuments', 'getCustomerDocumentsTable',{},(tableData)=>{
            });
        },
        ctxAdditionalParams() {
            return {
                'customer_id':  this.customerId
            }
        },
        getActions() {
            return [
                {
                    title: this.$t('common.button.create'),
                    icon: 'plus',
                    click: () => {
                        this.$root.$children[0].openModal('customer-document-modal', {customerId: this.customerId}, this.refreshTable)
                    },
                },
                {
                    title: this.$t('common.button.upload'),
                    icon: 'upload',
                    click: () => {
                        this.$root.$children[0].openModal('attachments-modal', {simple_customer_id: this.customerId}, this.refreshTable, {})
                    }
                }
            ]
        },
        customerDocumentUrl(file) {
            this.$root.openDocument(file, 'simple')
        },
        downloadCustomerDocumentUrl(file) {
            this.$root.downloadDocument(file, 'simple')
        },
        deleteDocument(id) {
            this.$removeConfirm('Customer/deleteDocument', {
                documentId: id
            }, this.refreshTable)
        },
        sendMail(id) {
            this.$root.$children[0].openModal('email-modal', {
                documentId: id,
                docType: 'simple'
            }, this.refreshTable, {})
        },
    }
}
</script>